<template>
  <div>
    <header class="header">
      <div class="header-text">付款给{{ payOrderInfo.mchName || '玄智科技' }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt=""/>
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt=""/>
      </div>

      <van-field v-model="money" type="number" :autofocus="true"/>

    </div>
    <ul class="plus-ul">
      <!-- 支付板块 -->
      <li style="border-radius:10px;">
        <!-- 支付金额板块 -->
        <div class="img-div">
          <img :src="wxImg" alt=""/>
          <div class="div-text">
            微信支付
          </div>
        </div>
      </li>
    </ul>
    <ul class="plus-ul" style="margin-top: 0px">
      <li style="border-radius:10px;">
        <div class="plus-remark">
          <van-field
              v-model="remark"
              type="textarea"
              maxlength="100"
              rows="3"
              autosize
              placeholder="请输入备注"
              show-word-limit
          />
        </div>
      </li>
    </ul>

    <!-- jeepay中，付款的点击事件 由 payment 修改为 pay  -->
    <div class="bnt-pay">
      <div
          class="bnt-pay-text"
          style="background-color:#07c160"
          @click="pay"
      >
        付款
      </div>
    </div>

    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <van-loading  size="48" />
      </div>
    </van-overlay>

  </div>
</template>

<script>
import {getNewPayPackage} from '@/api/api'
import config from "@/config";
import {Toast} from "vant";

export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  data: function () {
    return {
      avatar: require("../../assets/icon/wx.svg"), // 商户头像默认
      resData: {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      money: '',
      mchOrderNo: '',
      remark: '',
      openId: '',
      show:false,
    }
  },

  mounted() {
    if (this.$route.query.openId) {
      this.openId = this.$route.query.openId
    }
    // else {
    // window.open('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb73fd82d3880027c&redirect_uri=https://pb.flow4j.com/no-auth/wechat/test&response_type=code&scope=snsapi_userinfo','_blank')
    // location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb73fd82d3880027c&redirect_uri=https://pb.flow4j.com/api/no-auth/wechat/test&response_type=code&scope=snsapi_userinfo')
    // }
  },

  methods: {


    // 支付事件
    pay() {
      if (!this.money) {
        Toast.fail('请输入付款金额');
        return
      }

      this.show = true

      // 该函数执行效果慢
      let that = this;
      that.randomOrderNo()
      getNewPayPackage('wxpay',this.money, this.mchOrderNo, this.remark, this.openId).then(res => {

        if (res.code != '0') {
          this.show = false
          return alert(res.msg || '订单创建异常');
        }

        if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
          this.show = false
          return alert(res.data.errMsg || '订单异常');
        }

        // location.href = res.data.codeUrl

        that.resData = res.data;

        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady);
          }
        } else {
          that.onBridgeReady();
        }


      }).catch(res => {
        this.show = false
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });

    },

    /* 唤醒微信支付*/
    onBridgeReady() {

      let that = this;

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', JSON.parse(that.resData.payInfo),
          function (res) {
            this.show = false
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // //重定向
              if (that.payOrderInfo.returnUrl) {
                location.href = that.payOrderInfo.returnUrl;
              } else {
                alert('支付成功！');
                window.WeixinJSBridge.call('closeWindow')
              }

            }
            if (res.err_msg == "get_brand_wcpay_request:cancel") {
              alert("支付取消");
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "get_brand_wcpay_request:fail") {
              alert('支付失败:' + JSON.stringify(res))
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "total_fee") {
              alert('缺少参数')
              window.WeixinJSBridge.call('closeWindow')
            }
          }
      );
    },

    randomOrderNo() {
      this.mchOrderNo = 'M' + new Date().getTime() + Math.floor(Math.random() * (9999 - 1000) + 1000)
    },

  }


}
</script>
<style lang="css" scoped>
@import './pay.css';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>